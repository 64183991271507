import MuiLink from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"
import { Link, PageProps } from "gatsby"
import React from "react"
import SEO from "../components/SEO"

const NotFoundPage: React.FC<PageProps> = () => (
  <>
    <SEO
      title="404 - Deze pagina bestaat niet"
      description="Helaas, de pagina die u zoekt bestaat niet."
      ogTitle="404 - Deze pagina bestaat niet"
      ogDescription="Helaas, de pagina die u zoekt bestaat niet."
    />
    <Typography variant="h2" color="primary" component="h1" align="center">
      404: Niet Gevonden
    </Typography>
    <Typography variant="body1" component="p" align="center">
      Jammer genoeg bestaat de pagina die u zoekt niet.
    </Typography>
    <Typography variant="body1" component="p" align="center">
      Klik{" "}
      <MuiLink component={Link} to="/">
        hier
      </MuiLink>{" "}
      om naar de homepagina te gaan
    </Typography>
  </>
)

export default NotFoundPage
